import axios from "axios";

export const axiosClient = axios.create({
  baseURL: "https://api.earningempire.org",
});

export const axiosAuthClient = axios.create({
  baseURL: "https://api.earningempire.org",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${
      sessionStorage.getItem("echobit-mlm-admin-token") &&
      JSON.parse(sessionStorage.getItem("echobit-mlm-admin-token") || "")
        .access_token
        ? JSON.parse(sessionStorage.getItem("echobit-mlm-admin-token"))
            .access_token
        : ""
    }`,
  },
});
