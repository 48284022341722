import React, { useContext, useEffect, useMemo, useState } from "react";
import { createGlobalStyle, keyframes } from "styled-components";
import {
  Coins,
  ChartBars,
  Referral,
  BarsUser,
  Briefcase,
  PieChartIcon,
  Wallet,
  ChartBars2,
  Doller,
  Safe,
} from "../assets/dashboard";
import Reveal from "react-awesome-reveal";
import { UserContext } from "../App";
import { axiosAuthClient } from "../utils/AxiosClient";
import User from "./User";
import { IconContext } from "react-icons";
import Swal from "sweetalert2";
import { FaCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { formatWalletAddress } from "../utils";
import moment from "moment";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: start;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    left: 40%;
    top: 40%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const iconSize = 40;
const iconSize2 = 80;
const iconColor = "#fff";
const iconColor2 = "rgba(6,174,177,0.1)";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const cards = [
  {
    name: "Total Purchase ($)",
    icon: ChartBars(iconSize, iconColor),
    icon2: ChartBars(iconSize2, iconColor2),
    doller: true,
    key: "total_investment",
  },
  {
    name: "Total User",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "number_of_users",
  },
  {
    name: "Active User",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "active_user_count",
  },
  {
    name: "Blocked User",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "blocked_user_count",
  },
  {
    name: "Previous Day Volume",
    icon: Referral(iconSize, iconColor),
    icon2: Referral(iconSize2, iconColor2),
    key: "previous_day_valume",
    doller: true,
  },
  {
    name: "Total Withdraw",
    icon: BarsUser(iconSize, iconColor),
    icon2: BarsUser(iconSize2, iconColor2),
    doller: true,
    key: "total_withdrowal",
  },
  {
    name: "Total Earning",
    icon: Coins(iconSize, iconColor),
    icon2: Coins(iconSize2, iconColor2),
    doller: true,
    key: "cumulative_earnings",
  },
  {
    name: "Total Wallet Balance",
    icon: Coins(iconSize, iconColor),
    icon2: Coins(iconSize2, iconColor2),
    doller: true,
    key: "cumulative_wallet_balance",
  },
];

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    axiosAuthClient
      .get("/user/admin/dashboard")
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const [data, setData] = useState([]);
  const [fetch, setFetch] = useState(false);

  useEffect(() => {
    axiosAuthClient
      .get("/user/admin/customers/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("Error", err);
      });
  }, [fetch]);

  const onSubmit = (id, is_active) => {
    axiosAuthClient
      .patch("/user/admin/customers/", { id, is_active })
      .then((res) => {
        setFetch(!fetch);
        Swal.fire({
          title: "Updated Successfully",
          text: "User status changed",
          icon: "success",
          timer: 5000,
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          text: "Error in updating status please try again after some time",
          icon: "error",
          timer: 5000,
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (props) => props.row.index + 1,
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (props) => moment(props.value).format("DD MMM, YYYY"),
      },
      {
        Header: "User",
        accessor: "wallet_address",
        Cell: (props) => formatWalletAddress(props.value),
      },
      {
        Header: "Sponsor Address",
        accessor: "parent.wallet_address",
        Cell: (props) => formatWalletAddress(props.value),
      },
      {
        Header: "Rank",
        accessor: "rank",
        Cell: (props) => (props.value ? props.value : "No rank"),
      },
      {
        Header: "Total Purchase",
        accessor: "total_purchase",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Wallet Balance",
        accessor: "wallet_balance",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Total Earning",
        accessor: "total_earning",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Withdraw",
        accessor: "total_withdraw",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Status",
        accessor: "is_active",
        Cell: (props) => (props.value ? "Active" : "Blocked"),
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (props) => (
          <IconContext.Provider value={{ size: 30 }}>
            <>
              <button
                disabled={props.row.original.is_active}
                className="border-0 px-2 badge w-auto badge-success"
                onClick={() => onSubmit(props.value, true)}
              >
                <FaCheck size={20} />
              </button>
              <button
                disabled={!props.row.original.is_active}
                className="border-0 ms-2 px-2 badge w-auto badge-failure"
                onClick={() => onSubmit(props.value, false)}
              >
                <IoCloseOutline size={20} />
              </button>
            </>
          </IconContext.Provider>
        ),
      },
    ],
    []
  );

  const mTable = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <GlobalStyles />

      <section className="container mt-4">
        <div className="row">
          {cards?.map((card, index) => (
            <div key={index} className="col-lg-3 col-md-6 mb-4">
              <div className="feature-box h-100 d-flex align-items-center justify-content-between f-boxed style-3">
                <div className="text mt-2">
                  <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={100}
                    duration={600}
                    triggerOnce
                  >
                    <p className="fs-20 mb-2">{card.name}</p>
                  </Reveal>
                  <Reveal
                    className="onStep"
                    keyframes={fadeInUp}
                    delay={200}
                    duration={600}
                    triggerOnce
                  >
                    <p style={{ color: "black" }} className="fs-25 fw-600">
                      {card.doller ? "$ " : ""}{" "}
                      {dashboardData[card.key] ? dashboardData[card.key] : 0}
                    </p>
                  </Reveal>
                </div>
                <Reveal
                  className="onStep"
                  keyframes={fadeInUp}
                  delay={0}
                  duration={600}
                  triggerOnce
                >
                  <div className="mcard-icon">{card.icon}</div>
                </Reveal>

                <i className="wm">{card.icon2}</i>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-white p-3 rounded">
          <div className="d-flex justify-content-between mt-3">
            <div className="flex-column">
              <h3 className="fw-bold mb-1">User</h3>
              <div className="fs-6 text-gray-400">
                Total {mTable.rows.length} users
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center position-relative my-1 ms-2">
                <input
                  type="text"
                  id="kt_filter_search"
                  className="form-control form-control-solid form-select-sm w-150px ps-9"
                  placeholder="Search User"
                  value={mTable.state.globalFilter}
                  onChange={(e) => mTable.setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table
              {...mTable.getTableProps()}
              className="table align-middle border fs-6 gy-5 dataTable no-footer"
            >
              <thead>
                {mTable.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        className={
                          column.render("Header") == "ACTION" ? "w-200px" : ""
                        }
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <span className="text-gray-400 fs-7">
                          {column.render("Header")}
                        </span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="arrow_carrot-up fa-lg" />
                          ) : (
                            <i className="arrow_carrot-down fa-lg" />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...mTable.getTableBodyProps()}>
                {(mTable.page.length > 0 &&
                  mTable.page.map((row) => {
                    mTable.prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })) || (
                    <tr>
                      <td colSpan={5} className="text-center">
                        <span className="fw-bold">
                          No data available in table
                        </span>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <div className="d-flex justify-content-between align-items-center mt-5">
              <div>
                <select
                  className="form-select form-select-sm form-select-solid"
                  value={mTable.state.pageSize}
                  onChange={(e) => mTable.setPageSize(parseInt(e.target.value))}
                >
                  {[10, 25, 50, 100].map((item) => (
                    <option>{item}</option>
                  ))}
                </select>
              </div>
              <div>
                <span>
                  Page{" "}
                  <strong>
                    {mTable.state.pageIndex + 1} of {mTable.pageOptions.length}
                  </strong>
                </span>
              </div>
              <div className="d-flex">
                <button
                  className="btn-main px-2 mx-2"
                  onClick={() => mTable.previousPage()}
                  disabled={!mTable.canPreviousPage}
                >
                  <IoIosArrowBack />
                </button>
                <button
                  className="btn-main px-2 mx-2"
                  onClick={() => mTable.nextPage()}
                  disabled={!mTable.canNextPage}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Dashboard;
