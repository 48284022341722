import React, { useState } from "react";
import { createGlobalStyle } from "styled-components";
import { axiosClient } from "../utils/AxiosClient";
import Swal from "sweetalert2";
import { useAuth } from "../core/auth/useAuth";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Login = () => {
  const [mail, setMail] = useState("");
  const [otp, setOtp] = useState("");
  const { login } = useAuth();

  const [optSent, setOtpSent] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (optSent) {
      axiosClient
        .post("/user/admin/verify_otp/", {
          email: mail,
          otp,
        })
        .then((res) => {
          login(res.data);
          Swal.fire({
            title: "Login Success",
            text: "You will be redirected to dashboard",
            icon: "success",
            timer: 5000,
          }).then(() => {
            window.location.href = "/dashboard";
          });
        })
        .catch((err) =>
          Swal.fire({
            title: "Error",
            text: `${err.response.data.error}`,
            icon: "error",
          }).then(() => {
            console.log(err);
          })
        );
    } else {
      axiosClient
        .post("/user/admin/login/", {
          email: mail,
        })
        .then((res) =>
          Swal.fire({
            title: "Success",
            text: "OTP sent on Email",
            icon: "success",
            timer: 5000,
          }).then(() => {
            setOtpSent(true);
            console.log(res);
          })
        )
        .catch((err) =>
          Swal.fire({
            title: "Error",
            text: `${err.response.data.error}`,
            icon: "error",
            timer: 5000,
          }).then(() => {
            console.log(err);
          })
        );
    }
  };

  return (
    <>
      <GlobalStyles />
      <section
        className="jumbotron breadcumb no-bg"
        style={{
          backgroundImage: `url(${"./img/background/subheader.jpg"})`,
          minHeight: "100vh",
        }}
      >
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-5 text-light wow fadeInRight"
                data-wow-delay=".5s"
              >
                <div className="spacer-10"></div>
                <h1>Create, sell or collect digital items.</h1>
                <p className="lead">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua
                  ut enim.
                </p>
              </div>
              <div
                className="col-lg-5 offset-lg-2 wow fadeIn"
                data-wow-delay=".5s"
              >
                <div className="box-login">
                  <h3 className="mb10">Login Now</h3>
                  <form
                    name="contactForm"
                    id="contact_form"
                    method="post"
                    onSubmit={onSubmit}
                    className="form-border"
                    action="#"
                  >
                    <div className="field-set">
                      <input
                        type="text"
                        name="mail"
                        className="form-control"
                        placeholder="Enter your email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                      />
                    </div>

                    {optSent ? (
                      <div className="field-set">
                        <input
                          type="text"
                          name="otp"
                          id="opt"
                          className="form-control"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="field-set">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-main btn-fullwidth color-2"
                      />
                    </div>

                    <div className="clearfix"></div>

                    <div className="spacer-single"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
