import React, { createContext, useContext, useState } from "react";

export const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthContext.Provider");
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const storedToken = JSON.parse(
    sessionStorage.getItem("echobit-mlm-admin-token")
  );
  const [token, setToken] = useState(storedToken || null);

  const login = (t) => {
    sessionStorage.setItem("echobit-mlm-admin-token", JSON.stringify(t));
    setToken(t);
  };

  const logout = () => {
    sessionStorage.removeItem("echobit-mlm-admin-token");
    setToken(null);
  };

  const contextValue = {
    token,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
