import React from "react";
import { Link } from "react-router-dom";
import {
  Breakpoint,
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { useAuth } from "../core/auth/useAuth";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

export default function Header({ className }) {
  const { token, logout } = useAuth();
  const [showmenu, btn_icon] = React.useState(false);

  return (
    <header className={`navbar white ${className}`} id="myHeader">
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img
                  src="/img/logo.png"
                  className="img-fluid d-block"
                  style={{ width: "160px" }}
                  alt="#"
                />
                <img
                  src="/img/logo-light.png"
                  className="img-fluid d-none"
                  style={{ width: "160px" }}
                  alt="#"
                />
              </NavLink>
            </div>
          </div>
          {token ? (
            <>
              <BreakpointProvider>
                <Breakpoint l down>
                  {
                    showmenu &&
                    <div className="menu">
                      <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </div>
                      <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/user">User</NavLink>
                      </div>
                      {/* <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/investment">Investment</NavLink>
                      </div> */}
                      <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/purchase-list">Purchase List</NavLink>
                      </div>
                      <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/withdraw">Withdraw</NavLink>
                      </div>
                      <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                        <NavLink to="/settings">Settings</NavLink>
                      </div>
                    </div>
                  }
                </Breakpoint>

                <Breakpoint xl>
                  <div className="menu">
                    <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                      <NavLink to="/dashboard">Dashboard</NavLink>
                    </div>
                    <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                      <NavLink to="/user">User</NavLink>
                    </div>
                    <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                      <NavLink to="/purchase-list">Purchase List</NavLink>
                    </div>
                    <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                      <NavLink to="/withdraw">Withdraw</NavLink>
                    </div>
                    <div className="navbar-item" onClick={() => btn_icon(prev => !prev)}>
                      <NavLink to="/settings">Settings</NavLink>
                    </div>
                  </div>
                </Breakpoint>
              </BreakpointProvider>
              <div className="mainside">
                <div className="connect-wal">
                  <button onClick={() => logout()} className="btn-main px-3">
                    Logout
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>

        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header >
  );
}

const NavLink = (props) => <Link {...props} />;
