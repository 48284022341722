import React from "react";
import Reveal from "react-awesome-reveal";
import { Link } from "react-router-dom";
// import SliderMainParticleGrey from '../components/SliderMainParticleGrey1';
// import CarouselCollectionRedux from '../components/CarouselCollectionReduxNew';
// import CarouselNewRedux from '../components/CarouselNewReduxNew';
// import AuthorListRedux from '../components/AuthorListRedux';
// import Catgor from '../components/Catgor';
// import Footer from '../components/footer';
import { createGlobalStyle, keyframes } from "styled-components";

const authors = [
  {
    avatar: "/img/author/author-1.jpg",
    username: "Monica Lucas",
    sales: 3.2,
  },
  {
    avatar: "/img/author/author-2.jpg",
    username: "Mamie Barnett",
    sales: 2.8,
  },
  {
    avatar: "/img/author/author-3.jpg",
    username: "Nicholas Daniels",
    sales: 2.5,
  },
  {
    avatar: "/img/author/author-4.jpg",
    username: "Lori Hart",
    sales: 2.2,
  },
  {
    avatar: "/img/author/author-5.jpg",
    username: "Jimmy Wright",
    sales: 1.9,
  },
  {
    avatar: "/img/author/author-6.jpg",
    username: "Karla Sharp",
    sales: 1.6,
  },
  {
    avatar: "/img/author/author-7.jpg",
    username: "Gayle Hicks",
    sales: 1.5,
  },
  {
    avatar: "/img/author/author-8.jpg",
    username: "Claude Banks",
    sales: 1.3,
  },
  {
    avatar: "/img/author/author-9.jpg",
    username: "Franklin Greer",
    sales: 0.9,
  },
  {
    avatar: "/img/author/author-10.jpg",
    username: "Stacy Long",
    sales: 0.8,
  },
  {
    avatar: "/img/author/author-11.jpg",
    username: "Ida Chapman",
    sales: 0.6,
  },
  {
    avatar: "/img/author/author-12.jpg",
    username: "Fred Ryan",
    sales: 0.5,
  },
];

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: #fff;
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

export default function Home() {
  return (
    <>
      <GlobalStyles />
    </>
  );
}
