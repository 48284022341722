import React, { useEffect, useMemo, useState } from "react";
import { createGlobalStyle } from "styled-components";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { axiosAuthClient } from "../utils/AxiosClient";
import moment from "moment";
import { AiOutlineExport } from "react-icons/ai";
import { formatWalletAddress } from "../utils";

const GlobalStyles = createGlobalStyle`
  .navbar{
    background: rgba(255,255,255,0.4);
    backdrop-filter: blur(5px);
    border-bottom: 0.5px solid rgb(242 153 58 / 60%);
  }
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: end;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    right: 5%;
    top: 30%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const Withdraw = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosAuthClient
      .get("/transaction/admin/withdraw/")
      .then((resp) => {
        setData(resp.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: (props) => props.row.index + 1,
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: (props) => moment(props.value).format("DD MMM, YYYY LT"),
      },
      {
        Header: "User",
        accessor: "customer.wallet_address",
        Cell: (props) => formatWalletAddress(props.value),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Fee",
        accessor: "fee",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Amount Given",
        accessor: "amount_given",
        Cell: (props) => "$" + " " + props.value,
      },
      {
        Header: "Transaction Hash",
        accessor: "transaction_hash",
        Cell: (props) => (
          <a
            href={`https://testnet.bscscan.com/tx/${props.value}`}
            target="__blank"
          >
            {formatWalletAddress(props.value)}{" "}
            {props.value ? <AiOutlineExport /> : <></>}
          </a>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => (
          <span className={`badge badge-${props.value}`}>
            {props.value.charAt(0).toUpperCase() + props.value.slice(1)}
          </span>
        ),
      },
    ],
    []
  );

  const mTable = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div>
      <GlobalStyles />

      <section className="container">
        <div className="d-flex justify-content-between mt-3">
          <div className="flex-column">
            <h3 className="fw-bold mb-1">Withdraw</h3>
            <div className="fs-6 text-gray-400">
              Total {mTable.rows.length} withdraw
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center position-relative my-1 ms-2">
              <input
                type="text"
                id="kt_filter_search"
                className="form-control form-control-solid form-select-sm w-150px ps-9"
                placeholder="Search Investment"
                value={mTable.state.globalFilter}
                onChange={(e) => mTable.setGlobalFilter(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table
            {...mTable.getTableProps()}
            className="table align-middle border fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              {mTable.headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={
                        column.render("Header") == "ACTION" ? "w-200px" : ""
                      }
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="text-gray-400 fs-7">
                        {column.render("Header")}
                      </span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="arrow_carrot-up fa-lg" />
                        ) : (
                          <i className="arrow_carrot-down fa-lg" />
                        )
                      ) : (
                        ""
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...mTable.getTableBodyProps()}>
              {(mTable.page.length > 0 &&
                mTable.page.map((row) => {
                  mTable.prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      ))}
                    </tr>
                  );
                })) || (
                <tr>
                  <td colSpan={5} className="text-center">
                    <span className="fw-bold">No data available in table</span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="d-flex justify-content-between align-items-center mt-5">
            <div>
              <select
                className="form-select form-select-sm form-select-solid"
                value={mTable.state.pageSize}
                onChange={(e) => mTable.setPageSize(parseInt(e.target.value))}
              >
                {[10, 25, 50, 100].map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </div>
            <div>
              <span>
                Page{" "}
                <strong>
                  {mTable.state.pageIndex + 1} of {mTable.pageOptions.length}
                </strong>
              </span>
            </div>
            <div className="d-flex">
              <button
                className="btn-main px-2 mx-2"
                onClick={() => mTable.previousPage()}
                disabled={!mTable.canPreviousPage}
              >
                <IoIosArrowBack />
              </button>
              <button
                className="btn-main px-2 mx-2"
                onClick={() => mTable.nextPage()}
                disabled={!mTable.canNextPage}
              >
                <IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Withdraw;
