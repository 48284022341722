import { createGlobalStyle } from "styled-components";
import { BsGlobe } from "react-icons/bs";
import { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { axiosAuthClient } from "../utils/AxiosClient";
import { CiPercent } from "react-icons/ci";

const GlobalStyles = createGlobalStyle`
.navbar{
  background: rgba(255,255,255,0.4);
  backdrop-filter: blur(5px);
  border-bottom: 0.5px solid rgb(242 153 58 / 60%);
}
  .col-white {
      color: #0d0c22;
  }
  .de_count{
    h3{
      font-size: 36px;
      margin-bottom: 0;
      span {
        font-size: 36px;
      }
    }
  }
  .btn-main.inline.white{
    background: rgba(131,100,266, .2);
  }
  .yelbg{
    background-color: rgb(255, 249, 225);
  }
  .yelbg-2{
    background-color: rgb(247, 255, 231);
  }
  .greybg{
    background-color: rgb(246, 245, 251);
  }
  h2{
    font-size: 30px;
    margin-bottom: 20px;
    margin-top:0;
  }
  .pb60 {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @media only screen and (max-width: 1199px) { 
    .breadcumb.h-vh{
      background-image: none !important;
    }
  }
  .mcard-icon  {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2993a;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  } 
  .feature-box.style-3 i.wm {
    position: absolute;
    font-size: 0px;
    display: flex;
    justify-content: end;
    background: none;
    width: auto;
    height: auto;
    color: rgba(6,174,177,0.1);
    right: 5%;
    top: 30%;
  }
  .feature-box.style-3{
    padding: 20px;
  }
`;

const Settings = () => {
  const [percentage, setPercentage] = useState();
  const [newPercentage, setNewPercentage] = useState();

  useEffect(() => {
    axiosAuthClient
      .get("/setting/admin/margin/")
      .then((res) => setPercentage(res.data.percentage))
      .catch((err) => console.log(err));
  }, []);

  const updateMargin = async () => {
    axiosAuthClient
      .patch("/setting/admin/margin/", { percentage: newPercentage / 100 })
      .then((res) =>
        Swal.fire({
          title: "Update Successfully",
          text: `Margin percentage updated to - ${newPercentage} %`,
          icon: "success",
          timer: 5000,
        }).then(() => {
          window.location.reload();
        })
      )
      .catch((err) => console.log(err));
  };

  return (
    <>
      <GlobalStyles />

      <section className="container section-2 d-flex align-items-center" style={{ minHeight: "100vh" }}>
        <div className="new-item-cards w-100">
          <div className="row align-items-center h-100 justify-content-center mt-4">
            <div className="col-md-6 col-lg-6 col-xl-5 mb-3">
              <div className="item-card h-100 bg-white">
                <div>
                  <div className="item-header-profile d-flex gap-2 align-items-center px-2">
                    <div className="m-card-1">
                      <CiPercent color="#111" size={25} />
                    </div>
                    <div>
                      <h5 className="mb-0">Margin Percentage</h5>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center mt-4 mx-4">
                    <h2 className="mb-0">{percentage * 100} %</h2>
                    <p>Currunt Margin </p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter margin %"
                      value={newPercentage}
                      onChange={(e) => setNewPercentage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="item-footer mb-2">
                  <div
                    className="item-desc cursor-pointer"
                    onClick={() => updateMargin()}
                  >
                    <span>Update Margin</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
