import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import { createGlobalStyle } from "styled-components";
import Dashboard from "./pages/Dashboard";
import Investment from "./pages/Investment";
import Withdraw from "./pages/Withdraw";
import Home from "./pages/Home";
import { createContext, useState } from "react";
import ProtectedRoute from "./routing/ProtectedRoute";
import Login from "./pages/Login";
import { AuthProvider } from "./core/auth/useAuth";
import User from "./pages/User";
import Settings from "./pages/settings";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

export const UserContext = createContext({
  data: {},
  setData: () => {},
});

const App = () => {
  const [data, setData] = useState({});

  return (
    <AuthProvider>
      <UserContext.Provider value={{ data, setData }}>
        <GlobalStyles />
        <Header />
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Authenticated route */}
          <Route exact path="/" element={<ProtectedRoute />}>
            <Route exact path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/user" element={<User />} />
            <Route path="/purchase-list" element={<Investment />} />
            <Route path="/withdraw" element={<Withdraw />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Routes>
      </UserContext.Provider>
    </AuthProvider>
  );
};

export default App;
